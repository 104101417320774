<template>
  <div>
    <app-header></app-header>

    <div class="login">
      <div class="login-logo text-center">
        <img src="../../assets/images/logo.png" alt="" class="" />
        <p class="text-center">{{$t('change Password')}}</p>
      </div>
      <div class="text-center">
        <form method="post" @submit.prevent="add()">
          <div class="page" v-if="done">
            <div class="field">
              <label for="password">{{ $t("password") }}</label>
              <Password
                toggleMask
                id="password"
                v-model="newPassword"
                :class="{ 'is-invalid': isInvalid && !newPassword }"
                :placeholder="$t('Please enter in the password')"
              />
            </div>

            <div class="field mb-3">
              <label for="confirmPassword">{{ $t("confirm password") }}</label>
              <Password
                toggleMask
                id="confirmPassword"
                v-model="confirmPassword"
                :class="{ 'is-invalid': isInvalid && !confirmPassword }"
                :placeholder="$t('Please enter in the confirm Password')"
              />
            </div>
            <button
              class="btn btn-primary btn-login "
              type="submit"
              :disabled="save"
            >
              {{ $t('save') }}
            </button>
          </div>
          <div class="page" v-else>
            <div class="field mb-3">
              <label for="name">4 DIGIT CODE</label>
              <InputText id="name" type="text" v-model="varifyMessage" />
            </div>

            <button
              class="btn btn-primary btn-login"
              type="submit"
              :disabled="save"
            >
              {{ $t('Send Code') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "../../layouts/header.vue";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      varifyMessage: null,
      newPassword: null,

      confirmPassword: null,
      done: false,
      save: false,
    };
  },

  methods: {
    add() {
      if (this.done) {
        if (this.phone && this.newPassword && this.confirmPassword) {
          this.save = true;
          this.$http
            .post(`auth/changePassword`, {
              phone: this.phone,
              varifyMessage: this.varifyMessage,
              newPassword: this.newPassword,
              confirmPassword: this.confirmPassword,
            })
            .then(
              (response) => {
                this.$toast.add({
                  severity: "success",
                  summary: this.$t("Done Successfully"),
                  detail: this.$t("Password Change Successfully"),
                  life: 3000,
                });
                this.$router.push(`/login`);
              },
              (err) => {
                this.save = false;
                this.$toast.add({
                  severity: "error",
                  summary: this.$t("There error"),
                  detail: err.response.data.message,
                  life: 3000,
                });
              }
            );
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("There error"),
            detail: this.$t("Please fill in the fields"),
            life: 3000,
          });
        }
      } else {
        if (this.phone && this.varifyMessage) {
          this.save = true;
          this.$http
            .post(`auth/validateEmailPhoneWithVarifyCode`, {
              phone: this.phone,
              varifyMessage: this.varifyMessage,
            })
            .then(
              (response) => {
                this.save = false;

                this.done = true;
                this.$toast.add({
                  severity: "success",
                  summary: this.$t("Done Successfully"),
                  detail: this.$t("Varify code Successfully"),
                  life: 1500,
                });
              },
              (err) => {
                this.save = false;
                this.$toast.add({
                  severity: "error",
                  summary: this.$t("There error"),
                  detail: err.response.data.message,
                  life: 3000,
                });
              }
            );
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("There error"),
            detail: this.$t("Please fill in the fields"),
            life: 3000,
          });
        }
      }
    },
  },
  async created() {
    this.phone = this.$route.query.phone;
    this.varifyMessage = this.$route.query.varifyMessage
      ? this.$route.query.varifyMessage
      : null;
  },
};
</script>
<style></style>
